export const handleIconClick = ({
  machineName,
  href,
  toggleContactUsModal,
  toggleAboutModal,
  toggleProjectsModal,
  toggleTeamModal,
  toggleTrashModal,
  toggleClockModal,
  toggleMinesweeperModal,
  toggleServicesModal,
  togglePaintModal,
  toggleSearchModal,
  togglePartnersModal,
  toggleRabbitModal,
}) => {
  switch (machineName) {
    case 'contactUs': {
      toggleContactUsModal(true);
      break;
    }
    case 'aboutUs': {
      toggleAboutModal(true);
      break;
    }
    case 'projects': {
      toggleProjectsModal(true);
      break;
    }
    case 'team': {
      toggleTeamModal(true);
      break;
    }

    case 'minesweepeer': {
      toggleMinesweeperModal(true);
      break;
    }
    case 'service': {
      toggleServicesModal(true);
      break;
    }
    case 'facebook':
    case 'linkedin':
    case 'instagram':
    case 'xSocial': {
      window.open(href);
      break;
    }
    case 'trash': {
      toggleTrashModal(true);
      break;
    }
    case 'paint': {
      togglePaintModal(true);
      break;
    }
    case 'search': {
      toggleSearchModal(true);
      break;
    }
    case 'partners': {
      togglePartnersModal(true);
      break;
    }
    case 'clock': {
      toggleClockModal(true);
      break;
    }
    case 'rabbit': {
      toggleRabbitModal(true);
      break;
    }
    default:
  }
};

export const isBrowser = () => typeof window !== 'undefined';

export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const pathToAlt = (el) => String(el).split('/').at(-1).split('.')[0];
