import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  box-shadow: inset -2px -2px #ffffff, inset 2px 2px #808080;

  ${(props) => props.theme.breakpoints.up('default')} {
    height: 28px;
    padding: 0 8px;
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    height: 32px;
    padding: 0 10px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    height: 36px;
    padding: 0 12px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    height: 38px;
    padding: 0 16px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    height: 51px;
    padding: 0 16px;
  }
`;

export const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  & svg {
    width: 100%;
    height: 100%;
  }

  ${(props) => props.theme.breakpoints.up('default')} {
    width: 20px;
    height: 20px;
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 22px;
    height: 22px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 22px;
    height: 22px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 26px;
    height: 26px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 28px;
    height: 28px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 31px;
    height: 31px;
  }
`;

export const Text = styled.div`
  font-family: ${(props) => props.theme.font.main};
  ${(props) => props.theme.breakpoints.up('default')} {
    font-size: 16px;
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 18px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 18px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    font-size: 18px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    font-size: 20px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    font-size: 28px;
  }
`;

export const ClockButton = styled.button`
  background: transparent;
`;
