import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  inset: 0;
  z-index: ${({ show }) => (show ? 99999 : -99999)};
  background-color: ${(props) => props.theme.palette.primary.light};
`;

export default Wrapper;
