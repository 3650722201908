import React from 'react';

import params from './data';
import { Menu, MenuItem, IconWrapper, Text } from './StyledStartMenu';
import { handleIconClick } from '../../../../helpers/global';
import { MainImage } from 'gatsby-plugin-image';

function StartMenu({ ...actions }) {
  const { data } = params;

  const handleClick = (machineName) => {
    if (machineName === 'blog') {
      window.open('/blog', '_blank');
    } else {
      handleIconClick({ machineName, ...actions });
    }
  };

  return (
    <Menu>
      {data.map((item) => (
        <MenuItem key={item.id} onClick={() => handleClick(item.machineName)}>
          <IconWrapper>
            <MainImage src={item.icon} width={34} />
          </IconWrapper>
          <Text>{item.name}</Text>
        </MenuItem>
      ))}
    </Menu>
  );
}

export default StartMenu;
