import styled from 'styled-components';

export const StyledFooter = styled.div`
  display: ${({ preLoading, showFooter }) =>
    preLoading && showFooter === null ? 'none' : 'flex'};
  background-color: #c0c0c0;
  z-index: ${({ isMenuOpen }) => (isMenuOpen ? 9999 : 3)};
  justify-content: space-between;
  box-shadow: inset 0px 4px ${(props) => props.theme.palette.background.light};

  ${(props) => props.theme.breakpoints.up('default')} {
    padding: 4px;
    height: 48px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 5px;
    height: 54px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    padding: 6px;
    height: 56px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    padding: 12px;
    height: 83px;
  }
`;

export const FlexGroup = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifycontent || 'space-between'};
  align-items: ${(props) => props.alignItems};
  position: ${(props) => props.position};
`;

export const ShadowEffect = styled.div`
  width: 6px;
  box-shadow: inset -2px -2px #808080, inset 2px 0px #fff, inset 0px -4px #fff,
    inset 0px 2px #fff;
  margin: 0 0 0 15px;
  height: 26px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    height: 29px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    height: 32px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    height: 34px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    height: 48px;
  }
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  z-index: 10;

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 30px;
    margin-left: 12px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 34px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 48px;
    margin-left: 24px;
  }
`;
