import { withArtDirection, getImage } from 'gatsby-plugin-image';

const getSources = (data, breakpoints) => {
  const imageSources = [];
  for (let index = 0; index < data.length; index++) {
    const element = data[data.length - index - 1].node;
    const breakpointsKeys = Object.keys(breakpoints);
    const breakpointKey = breakpointsKeys[index];
    if (index) {
      imageSources.push({
        media: `(min-width: ${breakpoints[breakpointKey]}px)`,
        image: getImage(element),
      });
    }
  }
  return withArtDirection(getImage(data[0].node), imageSources);
};

export default getSources;
