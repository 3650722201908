import { useEffect, useState } from 'react';

import { isBrowser } from '../helpers/global';

function useMediaQuery(size) {
  const query = `(min-width: ${size}px)`;

  const getMatches = (param) => {
    if (isBrowser()) {
      return window.matchMedia(param).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
}

export default useMediaQuery;
