export default {
  data: [
    {
      icon: '/icons/Search_icon.svg',
      machineName: 'search',
      name: 'Search',
      id: 1,
    },
    {
      icon: '/icons/about_us.svg',
      machineName: 'aboutUs',
      name: 'About Us',
      id: 2,
    },
    {
      icon: '/icons/desktopIcons/blog.svg',
      machineName: 'blog',
      name: 'Blog',
      id: 3,
    },
    {
      icon: '/icons/desktopIcons/contacts.svg',
      machineName: 'contactUs',
      name: 'Contacts',
      id: 4,
    },
    {
      icon: '/icons/desktopIcons/project.svg',
      machineName: 'projects',
      name: 'Projects',
      id: 5,
    },
    {
      icon: '/icons/team_page.svg',
      machineName: 'team',
      name: 'Team',
      id: 6,
    },
    {
      icon: '/icons/our_services.svg',
      machineName: 'service',
      name: 'Our Services',
      id: 7,
    },
    {
      icon: '/icons/partners.svg',
      machineName: 'partners',
      name: 'Partners',
      id: 8,
    },
    {
      icon: '/icons/paint.svg',
      machineName: 'paint',
      name: 'Paint',
      id: 9,
    },
    {
      icon: '/icons/minesweeper.svg',
      machineName: 'minesweepeer',
      name: 'Minesweeper',
      id: 10,
    },
  ],
};
