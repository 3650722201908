import React, { memo, useEffect } from 'react';

import Stars from './Stars';
import Wrapper from './StyledSleepingMode';
import useIdle from '../../../hooks/useIdle';

function SleepingMode({ isSleepingModeOn, setSleepingMode }) {
  const idle = useIdle(1000000);

  useEffect(() => {
    setSleepingMode(idle);
  }, [idle]);

  if (!isSleepingModeOn) {
    return null;
  }

  return (
    <Wrapper show={isSleepingModeOn}>
      <Stars />
    </Wrapper>
  );
}

export default memo(SleepingMode);
