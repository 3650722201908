import React, { useEffect, useRef } from 'react';

import { randomIntFromInterval } from '../../../../helpers/global';

export default function Stars() {
  const canvasRef = useRef();
  const maxDepth = 40;
  const particleAmount = 700;
  const speed = 0.3;

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const context = canvas.getContext('2d');
    let maxDistributionX;
    let maxDistributionY;

    const particles = new Array(particleAmount);

    const setWindowRelatedProperties = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      maxDistributionX = window.innerWidth / 8;
      maxDistributionY = window.innerHeight / 4;
    };

    setWindowRelatedProperties();

    window.addEventListener('resize', setWindowRelatedProperties);

    context.fillStyle = 'rgb(34, 34, 34)';
    context.fillRect(0, 0, canvas.width, canvas.height);

    for (let i = 0; i < particles.length; i += 1) {
      particles[i] = {
        x: randomIntFromInterval(-maxDistributionX, maxDistributionX),
        y: randomIntFromInterval(-maxDistributionY, maxDistributionY),
        z: randomIntFromInterval(1, maxDepth),
      };
    }

    const moveParticles = () => {
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      context.fillStyle = 'rgba(34, 34, 34, .5)';
      context.fillRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < particles.length; i += 1) {
        particles[i].z -= speed;

        if (particles[i].z <= 0) {
          particles[i].x = randomIntFromInterval(
            -maxDistributionX,
            maxDistributionX
          );
          particles[i].y = randomIntFromInterval(
            -maxDistributionY,
            maxDistributionY
          );
          particles[i].z = maxDepth;
        }

        const k = 100 / particles[i].z;
        const newX = particles[i].x * k + centerX;
        const newY = particles[i].y * k + centerY;

        if (
          newX >= 0 &&
          newX <= window.innerWidth &&
          newY >= 0 &&
          newY <= window.innerHeight
        ) {
          const size = (1 - particles[i].z / maxDepth) * 5;
          context.beginPath();
          context.fillStyle = '#ddd';
          context.arc(newX, newY, size / 2, 0, Math.PI * 2, false);
          context.closePath();
          context.fill();
        }
      }
      window.requestAnimationFrame(moveParticles);
    };
    window.requestAnimationFrame(moveParticles);

    return () => {
      window.cancelAnimationFrame(moveParticles);
    };
  }, []);

  return <canvas ref={canvasRef} />;
}
