import styled, { css } from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  font-family: ${(props) => props.theme.font.main};
  color: ${(props) => props.theme.palette.primary.main};
  background: silver;
  box-shadow: inset -2px -2px #010101, inset 2px 2px #ffffff,
    inset -4px -4px #808080, inset 4px 4px #e3e3e3;
  ${(props) => props.theme.breakpoints.up('default')} {
    padding: 7px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 8px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 9px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    padding: 10px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    padding: 12px;
  }
  ${(props) =>
    props.focus
      ? css`
          svg {
            box-shadow: inset -2px -2px #ffffff, inset 2px 2px #010101,
              inset -4px -4px #e3e3e3, inset 4px 4px #808080;
            outline: 2px dotted #000;
            outline-offset: -8px;
          }
        `
      : {}};
  ${(props) =>
    props.isOpen
      ? css`
           {
            box-shadow: inset -2px -2px #ffffff, inset 2px 2px #010101,
              inset -4px -4px #e3e3e3, inset 4px 4px #808080;
            outline: 2px dotted #000;
            outline-offset: -8px;
          }
        `
      : {}};
`;

export const IconWrapper = styled.div`
  ${(props) => props.theme.breakpoints.up('default')} {
    width: 20px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 23px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 25px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 25px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 34px;
  }
`;

export const Text = styled.div`
  ${(props) => props.theme.breakpoints.up('default')} {
    font-size: 16px;
    margin-left: 5px;
  }
  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 18px;
    margin-left: 6px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    font-size: 20px;
    margin-left: 6px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    font-size: 28px;
    margin-left: 8px;
  }
`;
