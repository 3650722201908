import styled from 'styled-components';

export const Menu = styled.div`
  position: absolute;
  padding: 2px 4px 4px 2px;
  bottom: calc(100% + 5px);
  box-shadow: inset -2px -2px #353535, inset 2px 2px #ffffff,
    inset -4px -4px #7e7e7e;
  background-color: #c0c0c0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('default')} {
    width: 162px;
    height: 336px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 190px;
    height: 348px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 202px;
    height: 406px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 242px;
    height: 486px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    width: 298px;
    height: 586px;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 32px;
  padding: 10px 9px;
  &:hover {
    background: ${(props) => props.theme.palette.common.darkLight};
    color: ${(props) => props.theme.palette.primary.light};
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 34px;
    padding: 12px 11px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    height: 40px;
    padding: 14px 13px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    height: 48px;
    padding: 18px 17px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    height: 58px;
    padding: 20px 19px;
  }
`;

export const Text = styled.div`
  font-family: ${(props) => props.theme.font.main};
  ${(props) => props.theme.breakpoints.up('default')} {
    font-size: 18px;
    margin-left: 12px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 20px;
    margin-left: 14px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    font-size: 22px;
    margin-left: 16px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    font-size: 24px;
    margin-left: 20px;
  }
  ${(props) => props.theme.breakpoints.up('xxl')} {
    font-size: 32px;
    margin-left: 24px;
  }
`;

export const IconWrapper = styled.div`
  & svg {
    ${(props) => props.theme.breakpoints.up('default')} {
      width: 32px;
      height: 32px;
    }
    ${(props) => props.theme.breakpoints.up('md')} {
      width: 34px;
      height: 34px;
    }
    ${(props) => props.theme.breakpoints.up('lg')} {
      width: 40px;
      height: 40px;
    }
    ${(props) => props.theme.breakpoints.up('xl')} {
      width: 48px;
      height: 48px;
    }
    ${(props) => props.theme.breakpoints.up('xxl')} {
      width: 58px;
      height: 58px;
    }
  }
`;
