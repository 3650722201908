import React from 'react';

import { ThemeProvider } from 'styled-components';

import Layout from './src/components/Layout/Layout';
import Store from './src/store';

import './src/styles/global.css';
import './src/styles/minesweeper.css';
import './src/styles/preloader.css';
import GlobalStyle from './src/styles';
import theme from './src/styles/theme';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';

export const wrapRootElement = ({ element, props }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Helmet
      script={[
        helmetJsonLdProp({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'Blockstars',
          url: 'https://blockstars.tech',
          logo: '/images/logo.png',
          sameAs: [
            'https://www.facebook.com/Blockstars-108453728439783',
            'https://twitter.com/Blockstarstech',
            'https://www.linkedin.com/company/blockstarstech',
          ],
        }),
      ]}
    />
    <Store>{element}</Store>
  </ThemeProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);
